<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="结单" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="flex justify-between">
			<el-form :inline="true" :model="form" @submit.native.prevent>
				<el-form-item label="核销码：">
					<el-input v-model="form.code" placeholder="请输入核销码" @keydown.native.enter="search()"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search()">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="order-box" v-loading="loading">
			<div class="orders">
				<table class="order-tb">
					<colgroup>
						<col class="w-45p" />
						<col class="w-20p" />
						<col class="w-10p" />
						<col class="w-10p" />
					</colgroup>
					<thead>
						<tr>
							<th>订单详情</th>
							<th>会员</th>
							<th>状态</th>
							<th>金额</th>
						</tr>
					</thead>
					<!-- 订单信息 -->
					<tbody v-if="item">
						<tr class="sep-row"></tr>
						<tr class="tr-th">
							<td colspan="5">
								<span class="tit text-gray">订单编号</span>
								<span class="text-black">{{item.order_no}}</span>
								<span class="tit text-gray">订单金额</span>
								<span class="text-black">￥{{item.amount_fee}}</span>
								<span class="tit text-gray">实付金额</span>
								<span class="text-black">￥{{item.actual_fee}}</span>
								<span class="tit text-gray">订单来源</span>
								<span class="text-black" v-if="item.order_origin == 1">小程序</span>
								<span class="text-black" v-if="item.order_origin == 2">线下门店</span>
								<span class="datetime fr padding-right-lg">{{item.created_at}}</span>
							</td>
						</tr>
						<tr class="tr-bd">
							<td>
								<div class="product-list">
									<div class="product-box" v-for="sub in item.order_item" :key="sub.id">
										<el-image class="img" :src="sub.cover" fit="cover">
											<div slot="error" class="el-image__error">{{vuex_project}}</div>
										</el-image>
										<div class="product-name">{{sub.name}}<span v-if="sub.specs" class="text-grey margin-left-sm">[{{sub.specs.sku_values}}]</span></div>
										<div class="count">X{{sub.number}}</div>
										<div class="price text-red width-80 text-right" v-if="sub.totalPrice">￥{{sub.totalPrice|toFixed2}}</div>
									</div>
									<!-- 1 上门  2 到店 -->
									<div v-if="item.serve_type == 1" class="padding-lr padding-bottom text-grey">
										【上门】{{item.contact_name}} {{item.phone}} {{item.address}}
									</div>
								</div>
							</td>
							<td>
								<div class="member-box" v-if="item.member_id">
									<el-avatar class="img" :src="item.member_avatar" v-if="item.member_avatar"></el-avatar>
									<el-avatar icon="el-icon-user-solid" v-else></el-avatar>
									<div class="user-name">
										<div>{{item.real_name}}</div>
										<div class="phone">{{item.member_phone}}</div>
									</div>
								</div>
								<div class="text-left text-grey padding-left-lg" v-else>散客</div>
							</td>
							<td>
								<!-- 订单状态 订单支付状态,0未付款 1 已付款  2退款中  3 已退款  4退款失败  5已完成 -->
								<div class="text-center text-blue" v-if="item.order_status == 0">未付款</div>
								<div class="text-center text-green" v-if="item.order_status == 1">已付款</div>
								<div class="text-center text-orange" v-if="item.order_status == 2">退款中</div>
								<div class="text-center text-grey" v-if="item.order_status == 3">已退款</div>
								<div class="text-center text-orange" v-if="item.order_status == 4">退款失败</div>
								<div class="text-center text-grey" v-if="item.order_status == 5">已完成</div>
							</td>
							<td>
								<div class="text-center text-red text-lg">￥{{item.actual_fee}}</div>
								<div class="text-center text-gray" v-if="item.pay_method == 1">微信支付</div>
								<div class="text-center text-gray" v-else-if="item.pay_method == 2">支付宝支付</div>
								<div class="text-center text-gray" v-else-if="item.pay_method == 3">余额支付</div>
								<div class="text-center text-gray" v-else-if="item.pay_method == 4">现金支付</div>
								<div class="text-center text-gray" v-else-if="item.order_origin == 1">在线支付</div>
							</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr>
							<td colspan="6" class="text-center padding text-grey">请输入核销码查询</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<el-form class="padding-top" :model="form">
			<el-form-item label="服务人员：">
				<el-radio-group v-model="form.technician">
					<el-radio :label="te.id" :key="te.id" v-for="te in technicians">{{te.nickname}}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">确认核销</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import rules from '../../common/common.rules.js'
	export default {
		data() {
			return {
				rules,
				loading: false,
				technicians: [],
				item: null,
				dataTable: [],
				id: this.$route.params.id,
				form: {
					code: '',
					order_id: undefined, // 订单id
					technician: undefined, // 技师id
				}
			};
		},
		computed: {

		},
		mounted() {
			// 获取所有技师
			this.$api.staff.getStaffs({
				page: 1,
				page_size: 999
			}).then(res => {
				console.log(res.data.data)
				this.technicians = res.data.data;
			})
			// 如果有id，则通过id查询
			if (this.id) {
				this.$api.order.getOrderByIdOrCode({
					id: this.id
				}).then(res => {
					console.log(res)
					this.loading = false;
					if (res.code == 200) {
						this.item = res.data;
						this.form.order_id = res.data.id;
						res.data.order_item.forEach(item=>{
							item.totalPrice = Number(item.number) * Number(item.price)
						})
					} else {
						this.$message.error(res.msg)
					}
				})
			}
		},
		methods: {
			onSubmit() {
				if (!this.form.code) {
					this.$message.error('请输入核销码');
					return;
				}
				if (!this.form.technician) {
					this.$message.error('请选择服务人员');
					return;
				}
				const loading = this.$loading()
				this.$api.order.useOrder(this.form).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$alert('核销成功');
						this.form = {
							code: '',
							order_id: undefined,
							technician: undefined
						}
						this.item = null;
						this.$util.getWaitCount();
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			search() {
				this.loading = true;
				this.$api.order.getOrderByIdOrCode({
					code: this.form.code
				}).then(res => {
					console.log(res)
					this.loading = false;
					if (res.code == 200) {
						this.item = res.data;
						this.form.order_id = res.data.id;
					} else {
						this.$message.error(res.msg)
					}
				})
			}
		},
	};
</script>

<style scoped lang="scss">
	.order-box {
		width: 99%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.search-box {
			display: flex;
			justify-content: space-between;
		}

		.order-tb {
			border-collapse: collapse;
			width: 100%;
			font-size: 12px;


			// 标题
			thead {
				th {
					height: 32px;
					font-weight: normal;
					line-height: 32px;
					text-align: center;
					background: #f5f5f5;
					color: #666;
				}
			}

			// 空行
			.sep-row {
				height: 20px;
			}

			// 标题行
			.tr-th {
				background: #f5f5f5;
				height: 31px;
				line-height: 31px;
				color: #aaa;
				overflow: hidden;

				td {
					// border-bottom-color: #f5f5f5;
				}
			}

			tbody {
				border-right: 1px solid #000;

				td {
					box-sizing: border-box;
					border: 1px solid #e5e5e5;
				}
			}

			.tit {
				padding-left: 20px;
			}

			.product-list {
				padding-top: 10px;
			}

			.product-box {
				padding: 0px 20px 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.img {
					width: 50px;
					height: 50px;
					border-radius: 6px;
					flex: 0 0 auto;
				}

				.product-name {
					flex: 1;
					padding-left: 10px;
					font-size: 18px;
					line-height: 25px;
				}

				.count {
					width: 50px;
					font-size: 14px;
					color: #999;
					text-align: center;
				}
			}

			.member-box {
				padding: 10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;

				.img {
					width: 50px;
					height: 50px;
					border-radius: 6px;
					flex: 0 0 auto;
				}

				.user-name {
					flex: 1 0 auto;
					padding-left: 10px;
					font-size: 18px;

					.phone {
						font-size: 14px;
						color: #999;
					}
				}

			}
		}

	}
</style>
